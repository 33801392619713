body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #222222;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.logo {
  width: 75px;
  margin-right: 20px;
}

.test-img,
.photo-img {
  width: 300px;
}

form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

button.btn {
  align-self: flex-start;
}

img.qr-icon {
  width: 20px;
}

.hide {
  display: none;
}

h1 {
  text-align: center;
}

header {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.pop-img {
  width: 100%;
}

.mini-img {
  height: 50px;
}
